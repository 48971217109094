import React from "react";
import CloseIcon from "../../SVGIcons/CloseIcon/CloseIcon";
import styles from "./DemoGatedBanner.module.scss";

type DemoGatedBannerType = {
  bannerText: string;
  handleOnClick: () => void;
};

const DemoGatedBanner: React.FC<DemoGatedBannerType> = ({
  bannerText,
  handleOnClick,
  ...rest
}) => {
  const bannerContentClasses = `${styles.infoBanner} ${styles.demoBanner}
  `;

  return (
    <div {...rest} className={styles.bannerWrapper}>
      <div className={bannerContentClasses}>
        {bannerText}
        <button onClick={handleOnClick}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default DemoGatedBanner;
