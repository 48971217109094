import React, { useEffect, useState } from "react";
import { IterableDataType } from "@types";
import PrimaryButton from "../../../Buttons/PrimaryButton/PrimaryButton";
import InputField from "../../../Forms/FormFields/InputField";

/* helpers */
import { Cookie } from "../../../../utils/Cookie";
import { isValidEmail } from "../../../../utils/validations";
import {
  mxpDemoModalFieldUpdated,
  mxpDemoModalStepOneDone,
} from "../../../../utils/mixpanelEvents/demoGatedModal";
import { toSeconds } from "../../../../utils/toSeconds";

/* styles */
import styles from "./DemoGatedForm.module.scss";
import { mailCheck } from "../../../../components/SignUpForm/helpers/mailCheck";
import { iterableSubmit } from "../../../../utils/iterableSubmit";
import { useSpecialties } from "../../../../hooks/useSpecialties";
import { leadCapture } from "@utils/segment/leadCapture";
import { modalBannerCompleted } from "@utils/segment/ModalBanner";

type Props = {
  modalCliniciansSubcopy: string;
  modalPracticeSize: number;
  modalCliniciansHeader: string;
  modalPrivacyNote: string;
  modalCtaText: string;
  callback: (
    isShowBanner: boolean,
    firstName?: string,
    lastName?: string
  ) => void;
};

enum InputType {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
  PROFESSION_COUNT = "professionType",
  CLINICIAN = "clinicians",
}

const fieldError = {
  [InputType.FIRST_NAME]: "",
  [InputType.LAST_NAME]: "",
  [InputType.EMAIL]: "",
  [InputType.PROFESSION_COUNT]: "",
  [InputType.CLINICIAN]: "",
};
const DemoGatedForm: React.FC<Props> = ({
  modalCliniciansSubcopy,
  modalCliniciansHeader,
  modalPracticeSize,
  modalPrivacyNote,
  modalCtaText,
  callback = null,
}) => {
  /* local state */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [professionType, setProfessionType] = useState("");
  const [clinician, setClinician] = useState("");
  const [inputError, setInputError] = useState(fieldError);
  const [mCheck, setMCheck] = useState({
    shouldShow: false,
    suggestion: "",
  });
  const [timeStart, setTimeStart] = useState(0);

  const clinicians = new Array(modalPracticeSize)
    .fill(null)
    .map((_, idx) => idx + 1);

  useEffect(() => {
    setTimeout(() => {
      window.mixpanelTrialSent = false;
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { specialties } = useSpecialties(0);

  const fireMixPanelEvent = (field, value) => {
    const timeEnd = Date.now();
    const spTime = timeStart ? timeEnd - timeStart : 0;
    mxpDemoModalFieldUpdated({
      sp_field: field,
      sp_field_value: value,
      sp_time: toSeconds(spTime),
    });
  };

  const onSuggest = suggestion => {
    setMCheck({
      shouldShow: true,
      suggestion: suggestion.full,
    });
  };
  const removeSuggestion = () => {
    setMCheck({
      shouldShow: false,
      suggestion: "",
    });
  };

  const onClickSuggestion = () => {
    setEmail(mCheck.suggestion);
    removeSuggestion();
  };

  const handleOnBlur = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: InputType
  ) => {
    const { value } = event.target;
    switch (type) {
      case InputType.EMAIL:
        if (isValidEmail(value) && email.trim()) {
          try {
            const { status } = await iterableSubmit({
              email: value,
              SIGN_UP_SOURCE: "Website - Demo Modal",
            });
            if (status === 200) {
              setMCheck({
                shouldShow: false,
                suggestion: "",
              });
            }
            fireMixPanelEvent("email", value);

            window.analytics.identify({
              account_status: "lead",
              email,
            });
          } catch (error) {
            mailCheck(value, onSuggest, removeSuggestion);
          }
        }
        return;
      case InputType.FIRST_NAME:
        if (!inputError[InputType.FIRST_NAME])
          fireMixPanelEvent("first_name", value);
        return;
      default:
        if (!inputError[InputType.LAST_NAME])
          fireMixPanelEvent("last_name", value);
        return;
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    type: InputType
  ) => {
    const { value } = event.target;
    switch (type) {
      case InputType.EMAIL:
        setEmail(value);
        if (isValidEmail(value)) {
          setInputError(current => {
            return {
              ...current,
              [InputType.EMAIL]: "",
            };
          });
        }

        break;
      case InputType.FIRST_NAME:
        setFirstName(value);
        if (value.trim()) {
          setInputError(current => {
            return {
              ...current,
              [InputType.FIRST_NAME]: "",
            };
          });
        }
        break;

      case InputType.LAST_NAME:
        setLastName(value);
        if (value.trim()) {
          setInputError(current => {
            return {
              ...current,
              [InputType.LAST_NAME]: "",
            };
          });
        }
        break;

      case InputType.PROFESSION_COUNT:
        setProfessionType(value);
        setInputError(current => {
          return {
            ...current,
            [InputType.PROFESSION_COUNT]: "",
          };
        });
        fireMixPanelEvent("specialty", value);
        break;

      default:
        setClinician(value);
        setInputError(current => {
          return {
            ...current,
            [InputType.CLINICIAN]: "",
          };
        });
        fireMixPanelEvent("practice_size", value);
        break;
    }
  };
  const handleOnFocus = () => {
    setTimeStart(Date.now());
  };

  const validateFields = () => {
    let fieldErrorObj = fieldError;
    fieldErrorObj.firstName = !firstName.trim() && "First name can't be blank";
    fieldErrorObj.lastName = !lastName.trim() && "Last name can't be blank";
    fieldErrorObj.professionType =
      !professionType && "Profession can't be blank";
    fieldErrorObj.clinicians = !clinician && "Practice size can't be blank";
    fieldErrorObj.email = !email.trim() && "Email can't be blank";
    if (!isValidEmail(email) && email.trim()) {
      fieldErrorObj.email = "Email is an invalid format";
    }

    setInputError(fieldErrorObj);
    if (Object.values(fieldErrorObj).filter(d => d).length > 0) return true;
    return false;
  };

  const handleSubmit = async () => {
    try {
      if (validateFields()) return;

      const practiceSize = parseInt(clinician);
      const moreText = modalPracticeSize === practiceSize ? "+" : "";
      const specialtyLabel = specialties.reduce((acc, option) => {
        if (professionType === option.value) {
          acc = option.label;
        }
        return acc;
      }, "");

      const analyticsData = {
        text: modalCtaText,
        email,
      };

      leadCapture(analyticsData);

      const data: IterableDataType = {
        FIRST_NAME: firstName,
        LAST_NAME: lastName,
        email,
        GLOBAL_PROFESSION: specialtyLabel,
        clinicians: clinician + moreText,
        SIGN_UP_SOURCE: "Website - Demo Modal",
      };
      const { status } = await iterableSubmit(data);
      if (status === 200) {
        Cookie.setCookie("sp_speciality", specialtyLabel, 365);
        Cookie.setCookie("sp_practice_size", data.clinicians!, 365);
        if (callback) {
          const isShowBanner = modalPracticeSize !== practiceSize ?? false;
          const fullName = firstName + " " + lastName;
          callback(isShowBanner, fullName, email);
          modalBannerCompleted("demo modal", modalCtaText, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            practice_size: parseInt(clinician, 10),
            specialty: professionType,
          });
          mxpDemoModalStepOneDone({
            sp_specialty: professionType,
            sp_practice_size: parseInt(clinician, 10),
          });
        }
      }
    } catch (error) {
      const isShowBanner = false;
      callback(isShowBanner);
      return error;
    }
  };

  const selectInputClassName = (value: string, field: string) => {
    return `${styles.selectInput} ${!value && styles.selectPlaceHolderColor} ${
      inputError[field] && styles.inputError
    }`;
  };

  return (
    <div className={styles.demoGatedForm}>
      <div className={styles.formFields}>
        <InputField
          customErrorIcon={inputError.firstName ? true : false}
          data-testid="modal-firt-name-input"
          value={firstName}
          onChange={e => handleChange(e, InputType.FIRST_NAME)}
          onBlur={e => handleOnBlur(e, InputType.FIRST_NAME)}
          onFocus={handleOnFocus}
          placeholder={"First name"}
          type="text"
        />
        {inputError.firstName && (
          <p className={styles.activeError}> {inputError.firstName}</p>
        )}
      </div>

      <div className={styles.formFields}>
        <InputField
          customErrorIcon={inputError.lastName ? true : false}
          data-testid="modal-last-name-input"
          value={lastName}
          onChange={e => handleChange(e, InputType.LAST_NAME)}
          onBlur={e => handleOnBlur(e, InputType.LAST_NAME)}
          onFocus={handleOnFocus}
          placeholder={"Last name"}
          type="text"
        />
        {inputError.lastName && (
          <p className={styles.activeError}> {inputError.lastName}</p>
        )}
      </div>

      <div className={styles.formFields}>
        <InputField
          customErrorIcon={inputError.email ? true : false}
          data-testid="modal-email-input"
          value={email}
          onChange={e => handleChange(e, InputType.EMAIL)}
          onFocus={handleOnFocus}
          onBlur={e => handleOnBlur(e, InputType.EMAIL)}
          placeholder={"Email"}
          type="text"
        />
        {inputError.email && (
          <p className={styles.activeError}> {inputError.email}</p>
        )}
        {mCheck.shouldShow && (
          <p className={styles.mCheckSuggestion}>
            Did you mean{" "}
            <span onClick={onClickSuggestion}>{mCheck.suggestion}</span>?
          </p>
        )}
      </div>

      <div className={styles.formFieldsWithLarginMargin}>
        <select
          data-testid="modal-profession-input"
          value={professionType}
          onChange={e => handleChange(e, InputType.PROFESSION_COUNT)}
          className={selectInputClassName(professionType, "professionType")}
          onFocus={handleOnFocus}
        >
          <option value="" disabled>
            Select your profession
          </option>
          {specialties.length > 0 &&
            specialties.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
        {inputError.professionType && (
          <p className={styles.activeError}> {inputError.professionType}</p>
        )}
      </div>

      <div
        className={styles.practiceLabel}
        data-testid="modal-clinicians-header"
      >
        {modalCliniciansHeader}
      </div>
      <div className={styles.formFieldsWithLarginMargin}>
        <select
          data-testid="modal-clinician-input"
          value={clinician}
          onChange={e => handleChange(e, InputType.CLINICIAN)}
          className={selectInputClassName(clinician, "clinicians")}
          onFocus={handleOnFocus}
        >
          <option value="" disabled>
            Select your practice size
          </option>
          {clinicians.length > 0 &&
            clinicians.map(value => (
              <option key={value} value={value}>
                {value === modalPracticeSize ? `${value}+` : value}
              </option>
            ))}
        </select>
        <div className={styles.modalSubCopyWithErrorMessage}>
          {inputError.clinicians && (
            <p className={styles.activeError}> {inputError.clinicians}</p>
          )}
          <p data-testid="modal-subcopy" className={styles.demoGatedSubcopy}>
            {modalCliniciansSubcopy}
          </p>
        </div>
      </div>

      <PrimaryButton
        onClickHandler={handleSubmit}
        buttonText={modalCtaText}
        noMxp={true}
        data-testid="modal-view-demo-button"
      />

      <p data-testid="modal-privacy-note" className={styles.modalPrivacyNote}>
        {modalPrivacyNote}
      </p>
    </div>
  );
};

export default DemoGatedForm;
