import React, { useEffect, useState } from "react";
import { Cookie } from "../../utils/Cookie";
import {
  mxpDemoModalStandard,
  mxpDemoModalStepTwoDone,
} from "../../utils/mixpanelEvents/demoGatedModal";
import { DEMO_PAGE } from "../../constants/demoPage";
import BookConsultationGatedModalQuery from "../../assets/StaticQueries/BookConsultationGatedModal.query";
import DemoGatedModalQuery from "../../assets/StaticQueries/DemoModal.query";
import DemoGatedModal from "../../components/Modals/DemoGatedModal";
import DemoGatedBanner from "../../components/Banners/DemoGatedBanner";
import useFormSuccessBannerStore from "../../sp-state/useFormSuccessBanner";
import useModalGatedFormStore from "../../sp-state/modals/useModalGatedFormStore";
import useShowGlobalBannerStore from "../../sp-state/useShowGlobalBanner";
import {
  modalBannerCompleted,
  modalBannerViewed,
} from "@utils/segment/ModalBanner";

const DemoGatedModalContainer: React.FC = () => {
  const { setFormSuccessBanner } = useFormSuccessBannerStore(state => state);
  const { modalGatedTrigger, setModalGatedTrigger } = useModalGatedFormStore(
    state => state
  );

  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showConsultationModal, setShowConsultationModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerText, setBannerText] = useState("");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const demoGatedModalData = DemoGatedModalQuery();
  const { excludeVisitorCookie } = demoGatedModalData;

  const setShowGlobalBanner = useShowGlobalBannerStore(
    state => state.setShowGlobalBanner
  );

  const isDemoPage =
    typeof window !== "undefined"
      ? window.location.href.includes("/demo")
      : false;

  const demoGatedModelConsulationData = BookConsultationGatedModalQuery();
  const { primaryCtaLink } = demoGatedModelConsulationData;
  useEffect(() => {
    if (typeof window !== "undefined") {
      const { search } = window.location;
      const isBypass = search?.includes(DEMO_PAGE.DEMO_BYPASS);

      const isBooked = search?.includes(DEMO_PAGE.DEMO_BOOKED);
      if (isBypass) {
        Cookie.setCookie(excludeVisitorCookie, true, 365);
      } else if (isBooked) {
        const demoBookedBannerText =
          "Thank you for booking a consultation. In the meantime, check out our demo!";
        if (bannerText !== demoBookedBannerText) {
          setBannerText(demoBookedBannerText);
          setShowBanner(true);
        }
        mxpDemoModalStandard("user: consultation call scheduled");
      } else if (!Cookie.getCookie(excludeVisitorCookie)) {
        mxpDemoModalStandard("user: demo modal step 1 viewed");
        setTimeout(() => {
          modalBannerViewed("demo modal", "Step 1");
        }, 500);

        setShowDemoPopup(true);
        setModalGatedTrigger(true);

        if (isDemoPage) {
          setShowGlobalBanner(false);
        } else {
          setShowGlobalBanner(true);
        }
      }
    }

    return () => {
      setShowGlobalBanner(true);
    };
  }, []);

  const sucessHandler = (isShowBanner, fullName, email) => {
    if (demoGatedModalData && fullName && email) {
      const { excludeVisitorCookie } = demoGatedModalData;
      Cookie.setCookie(excludeVisitorCookie, true, 365);
      setEmail(email);
      setFullName(fullName);
      if (isShowBanner) {
        setShowDemoPopup(false);
        setFormSuccessBanner(true);

        if (isDemoPage) {
          setShowGlobalBanner(true);
        }

        return;
      }

      modalBannerViewed("demo modal", "Step 2");

      setShowDemoPopup(false);
      triggerConsulationModal(true);

      if (isDemoPage) {
        setShowGlobalBanner(true);
      }
    }
  };

  const triggerConsulationModal = isShow => {
    setShowConsultationModal(isShow);
    setShowDemoPopup(isShow);
    setModalGatedTrigger(isShow);

    if (isShow) {
      mxpDemoModalStandard("user: demo modal step 2 viewed");
    }
  };

  const successActionHandler = (isSendAction: boolean, ctaText: string) => {
    modalBannerCompleted("demo modal", ctaText);
    if (isSendAction) {
      if (typeof window !== undefined)
        window.location.href = `${primaryCtaLink}?name=${fullName}&email=${email}`;
      mxpDemoModalStepTwoDone({
        sp_cta_text: demoGatedModelConsulationData.primaryCtaText,
      });
      return;
    }

    triggerConsulationModal(false);
    setBannerText(
      "Thanks for sharing! Check out all the features we offer for practices like yours."
    );
    setShowBanner(true);
    mxpDemoModalStepTwoDone({
      sp_cta_text: demoGatedModelConsulationData.bypassCtaText,
    });
  };

  const hideBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {isDemoPage && demoGatedModalData && showDemoPopup && (
        <DemoGatedModal
          modal={
            showConsultationModal
              ? demoGatedModelConsulationData
              : demoGatedModalData
          }
          trigger={modalGatedTrigger}
          sucessHandler={sucessHandler}
          isShowSucessModal={showConsultationModal}
          successActionHandler={successActionHandler}
        />
      )}

      {showBanner && (
        <DemoGatedBanner handleOnClick={hideBanner} bannerText={bannerText} />
      )}
    </>
  );
};

export default DemoGatedModalContainer;
