import React from "react";
import { ContentModulePType } from "@types";
import styles from "./ContentModulePContainer.module.scss";

type ContentModulePContainerProps = {
  contentModuleP: ContentModulePType;
  slug: string;
  belowOfSection: "features" | "testimonial";
  className?: string;
};

const ContentModulePContainer: React.FC<ContentModulePContainerProps> = ({
  contentModuleP,
  slug,
  belowOfSection,
  className = "",
}) => {
  const contentIndex = belowOfSection === "features" ? 0 : 1;
  const title = contentModuleP.contentRepeater[contentIndex]?.contentTitle;
  const contentCopy = contentModuleP.contentRepeater[contentIndex]?.contentCopy;

  return (
    <section className={className}>
      <div>
        <h1 className={styles.contentTitle}>{title}</h1>
        <div
          dangerouslySetInnerHTML={{ __html: contentCopy }}
          className={styles.contentCopy}
        />
      </div>
    </section>
  );
};

export default ContentModulePContainer;
