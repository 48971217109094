import { TrialSignUpSpecialtiesItemType } from "@types";
import { useEffect, useState } from "react";
import SPFetch from "../services/SPFetch.class";

export const useSpecialties = (specialtiesId: number) => {
  const [specialties, setSpecialties] =
    useState<TrialSignUpSpecialtiesItemType>([]);

  useEffect(() => {
    let isMounted = true;

    SPFetch.get(
      `${process.env.GATSBY_WS_URL}/api/getGlobalProfessions/${specialtiesId}`
    ).then(res => {
      const { data } = res;
      if (isMounted) {
        data.forEach(specialty => {
          setSpecialties(prevState => [
            ...prevState,
            {
              value: specialty.id,
              selected: specialty.id === "1",
              label: specialty.attributes.name,
            },
          ]);
        });
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  specialties.sort((a, b) => a.label.localeCompare(b.label));

  specialties.forEach(i => {
    if (i.label === "Other") {
      specialties.push(specialties.splice(specialties.indexOf(i), 1)[0]);
    }
  });

  return {
    specialties,
  };
};
