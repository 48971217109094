import * as React from "react";
import { PageProps, graphql } from "gatsby";
import MainLayout from "../../layouts/main";

import ContentModulePContainer from "../../containers/ContentModulePContainer/ContentModulePContainer";
import FeatureCardsOContainer from "../../containers/FeatureCardsOContainer/FeatureCardsOContainer";
import FeatureCardsContainer from "../../containers/FeatureCardsContainer";
import FeaturesModulePContainer from "../../containers/FeaturesModulePContainer/FeaturesModulePContainer";
import HeroPContainer from "../../containers/HeroPContainer/HeroPContainer";
import HomePageVideoContainer from "../../containers/HomePageVideoContainer/HomePageVideoContainer";
import ResourcesSliderPContainer from "../../containers/ResourcesSliderPContainer/ResourcesSliderPContainer";
import TestimonialSliderPContainer from "../../containers/TestimonialSliderPContainer/TestimonialSliderPContainer";
import DemoGatedModalContainer from "../../containers/DemoGatedModalContainer";

import {
  ContentModulePType,
  FeatureCardsPType,
  FeaturesModulePType,
  HeroPType,
  HeroWithVideoPType,
  ResourcesSliderPType,
  TestimonialSliderPType,
  YoastSEOType,
} from "@types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type SpecialityPageType = {
  wpPage: {
    seo: YoastSEOType;
    slug: string;
    template: {
      heroP: HeroPType;
      heroWithVideoP: HeroWithVideoPType;
      featuresModuleP: FeaturesModulePType;
      testimonialSliderP: TestimonialSliderPType;
      resourcesSliderP: ResourcesSliderPType;
      contentModuleP: ContentModulePType;
      featureCardsP: FeatureCardsPType;
    };
  };
};

const SpecialtyPage: React.FC<PageProps<SpecialityPageType>> = ({ data }) => {
  const { wpPage } = data;
  const {
    slug,
    template: {
      heroP,
      heroWithVideoP,
      featuresModuleP,
      testimonialSliderP,
      resourcesSliderP,
      contentModuleP,
      featureCardsP,
    },
  } = wpPage;

  const withFeaturesCards =
    featureCardsP.featuresCardsList &&
    featureCardsP.featuresCardsList.length > 0;
  const featuresCardSection = withFeaturesCards ? (
    <FeatureCardsContainer featureCards={featureCardsP} />
  ) : (
    <FeatureCardsOContainer />
  );

  return (
    <>
      <MainLayout hasFooterLargeCta={true}>
        {wpPage.seo && (
          <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
        )}
        <HeroPContainer props={heroP} />
        <HomePageVideoContainer
          props={heroWithVideoP}
          className="pad-bottom--large"
        />

        {featuresCardSection}

        <FeaturesModulePContainer props={featuresModuleP} />
        {contentModuleP?.contentRepeater && (
          <ContentModulePContainer
            contentModuleP={contentModuleP}
            slug={slug}
            belowOfSection="features"
            className="full-width narrow margin-bottom--large"
          />
        )}
        <TestimonialSliderPContainer
          props={testimonialSliderP}
          hasCustomerSatisfaction
        />
        {contentModuleP?.contentRepeater && (
          <ContentModulePContainer
            contentModuleP={contentModuleP}
            slug={slug}
            belowOfSection="testimonial"
            className="full-width narrow margin-bottom--large margin-top--large"
          />
        )}
        <ResourcesSliderPContainer
          resourcesSliderTitle={resourcesSliderP.resourcesSliderTitle}
          resourcesSliderSubcopy={resourcesSliderP.resourcesSliderSubcopy}
          resourcesSliderList={resourcesSliderP.resourcesSliderList}
        />
      </MainLayout>
      <DemoGatedModalContainer />
    </>
  );
};

export const SPECIALTY_PAGE_QUERY = graphql`
  query SpecialtyTemplateQuery(
    $id: String
    $featureModuleImageWidth: Int = 800
  ) {
    wpPage(id: { eq: $id }) {
      slug
      ...YoastSEOFragment
      template {
        ... on WpSpecialtyTemplate {
          ...FeatureCardsFragment
          templateName
          featuresModuleP {
            featuresModuleSubcopy
            featuresModuleTitle
            featuresModuleList {
              featuresModuleAssetToggle
              featuresModuleItemBadge
              featuresModuleItemBadgeColor
              featuresModuleItemCta
              featuresModuleItemCtaAriaText
              featuresModuleItemCtaText
              featuresModuleItemImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: $featureModuleImageWidth
                      placeholder: BLURRED
                      formats: [WEBP, JPG, AUTO]
                    )
                  }
                }
              }
              featuresModuleItemQuote
              featuresModuleItemQuoteAuthor
              featuresModuleItemSubcopy
              featuresModuleItemSubcopySecondary
              featuresModuleItemTitle
              featuresModuleItemTitleColor
              featuresModuleItemTitleSecondary
              featuresModuleItemTitleSecondaryColor
              featuresModuleItemVideo {
                altText
                mediaItemUrl
              }
              featuresModuleItemVideoImage {
                altText
                mediaItemUrl
              }
            }
          }
          heroWithVideoP {
            videoId
            videoSubcopy
            videoTitle
            videoBackgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1200
                    placeholder: BLURRED
                    formats: [WEBP, JPG, AUTO]
                  )
                }
              }
            }
          }
          heroP {
            heroButtonLink
            heroButtonText
            heroCcText
            heroInputPlaceholder
            heroSubcopy
            heroTitle
          }
          testimonialSliderP {
            testimonialSubcopy
            testimonialModuleRelationship {
              ... on WpTestimonial {
                testimonialsC {
                  testimonialVideoBackgroundImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          width: 800
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                  testimonialWistiaId
                  testimonialsAuthor
                  testimonialsLabel
                  testimonialsLocation
                  testimonialsQuote
                }
              }
            }
          }
          resourcesSliderP {
            resourcesSliderTitle
            resourcesSliderSubcopy
            resourcesSliderList {
              ... on WpResource {
                resourcesCategories {
                  nodes {
                    name
                  }
                }
                title
                uri
                resourcesC {
                  resourceType
                  resourcesCardsImage {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 800
                          placeholder: BLURRED
                          formats: [WEBP, JPG, AUTO]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
          contentModuleP {
            contentRepeater {
              contentCopy
              contentTitle
            }
          }
        }
      }
    }
  }
`;
export default SpecialtyPage;
