import Mailcheck from "mailcheck";

const domains = [
  "gmail.com",
  "outlook.com",
  "hotmail.com",
  "yahoo.com",
  "aol.com",
  "mail.com",
  "icloud.com",
  "mac.com",
];
const secondLevelDomains = [
  "gmail",
  "outlook",
  "hotmail",
  "yahoo",
  "aol",
  "mail",
  "icloud",
  "mac",
];
const topLevelDomains = ["com"];

export const mailCheck = (email, onSuggest, removeSuggestion) => {
  Mailcheck.run({
    email,
    domains,
    secondLevelDomains,
    topLevelDomains,
    suggested: onSuggest,
    empty: removeSuggestion,
  });
};
