import React from "react";

import { Link } from "gatsby";
import { FeatureCardsPType } from "@types";

import FeatureCards from "../../components/FeatureCards/FeatureCards";
import RightArrow from "../../components/SVGIcons/RightArrow/RightArrow";

import styles from "../FeatureCardsOContainer/FeatureCardsOContainer.module.scss";

export type FeatureCardsContainerType = {
  featureCards: FeatureCardsPType;
  page?: string;
};

const FeatureCardsContainer: React.FC<FeatureCardsContainerType> = ({
  page,
  featureCards,
}) => {
  return (
    <section className={styles.sectionContainer}>
      {featureCards.featuresCardsList && (
        <FeatureCards page={page} featureCards={featureCards} />
      )}

      <div
        className={`full-width text-alignment ${styles.viewFeaturesContainer}`}
      >
        <p className={styles.viewFeatures}>
          <Link
            className={styles.viewFeaturesLink}
            to={featureCards?.featuresCardsCta!}
            aria-label="View features"
          >
            <span className={styles.viewFeaturesLinkText}>
              {featureCards?.featuresCardsCtaText}
            </span>
            <span className={styles.viewFeaturesArrowIcon}>
              <RightArrow />
            </span>
          </Link>
        </p>
      </div>
    </section>
  );
};

export default FeatureCardsContainer;
