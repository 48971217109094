import * as React from "react";

import FeatureCard from "./FeatureCard/FeatureCard";
import { GlobalFeaturesCardsQuery } from "../../assets/StaticQueries/GlobalFeaturesCards.query";
import { FeatureCardsPType } from "../../assets/Types/AcfGroupTypes/FeatureCardsP.type";

import styles from "./FeatureCards.module.scss";

const FeatureCards: React.FC<{
  featureCards?: FeatureCardsPType;
  threeColumns?: boolean;
  page?: string;
}> = ({ featureCards, threeColumns = false, page }) => {
  const { featuresCardsTitle, featuresCardsList, featuresCardsSubcopy } =
    featureCards || GlobalFeaturesCardsQuery();

  const cardGrid = featuresCardsList.map((card, index) => (
    <FeatureCard
      page={page}
      key={index}
      featuresCardsItemIcon={card.featuresCardsItemIcon}
      featuresCardsItemTitle={card.featuresCardsItemTitle}
      featuresCardsItemBodycopy={card.featuresCardsItemBodycopy}
    />
  ));

  return (
    <>
      <div
        data-testid="div:FeatureCards"
        className={`full-width text-alignment ${styles.featureCardTitleContainer}`}
      >
        <h2
          className={styles.featureCardTitle}
          dangerouslySetInnerHTML={{ __html: featuresCardsTitle }}
        />
        {featuresCardsSubcopy?.length > 0 && (
          <p
            data-testid="p:featuresCardsSubcopy"
            className={styles.featureSubcopy}
          >
            {featuresCardsSubcopy}
          </p>
        )}
      </div>
      <div
        className={`full-width ${styles.gridContainer} ${
          threeColumns ? "threeGrid" : ""
        }`}
      >
        {cardGrid}
      </div>
    </>
  );
};

export default FeatureCards;
