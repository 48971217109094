import { useStaticQuery, graphql } from "gatsby";
import { BookConsultationGatedModalType } from "@types";

const BookConsultationGatedModalQuery = (): BookConsultationGatedModalType => {
  const {
    wp: {
      graphqlOptions: { bookConsultationGatedModal },
    },
  } = useStaticQuery(graphql`
    query BookConsultationGatedModalQuery {
      wp {
        graphqlOptions {
          bookConsultationGatedModal {
            headline
            subcopy
            primaryCtaText
            primaryCtaLink
            bypassCtaText
          }
        }
      }
    }
  `);
  return bookConsultationGatedModal;
};

export default BookConsultationGatedModalQuery;
