import {
  MxpDemoModalFieldUpdatedType,
  MxpDemoModalStandardType,
  MxpDemoModalStepOneDoneType,
  MxpDemoModalStepTwoDoneType,
  MxpFC,
} from "@types";

export const mxpDemoModalStandard = (label: MxpDemoModalStandardType) => {
  window.trackMixpanelEvent(label);
};

export const mxpDemoModalFieldUpdated: MxpFC<MxpDemoModalFieldUpdatedType> =
  data => {
    window.trackMixpanelEvent("user: demo modal step 1 field updated", data);
  };

export const mxpDemoModalStepOneDone: MxpFC<MxpDemoModalStepOneDoneType> =
  data => {
    window.trackMixpanelEvent("user: demo modal step 1 completed", data);
  };

export const mxpDemoModalStepTwoDone: MxpFC<MxpDemoModalStepTwoDoneType> =
  data => {
    window.trackMixpanelEvent("user: demo modal step 2 completed", data);
  };
