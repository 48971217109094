import { useStaticQuery, graphql } from "gatsby";
import { DemoGatedModalType } from "@types";

const DemoGatedModalQuery = (): DemoGatedModalType => {
  const {
    wp: {
      graphqlOptions: { demoGatedModal },
    },
  } = useStaticQuery(graphql`
    query demoGatedModalQuery {
      wp {
        graphqlOptions {
          demoGatedModal {
            gatedHeadline
            gatedSubcopy
            cliniciansHeader
            practiceSize
            cliniciansSubcopy
            ctaText
            privacyNote
            excludeVisitorCookie
          }
        }
      }
    }
  `);
  return demoGatedModal;
};

export default DemoGatedModalQuery;
