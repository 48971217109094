import * as React from "react";
import FeatureCards from "../../components/FeatureCards/FeatureCards";
import RightArrow from "../../components/SVGIcons/RightArrow/RightArrow";

import styles from "./FeatureCardsOContainer.module.scss";

const FeatureCardsOContainer: React.FC<{ page?: string }> = ({ page }) => (
  <section className={styles.sectionContainer}>
    <FeatureCards page={page} />
    <div
      className={`full-width text-alignment ${styles.viewFeaturesContainer}`}
    >
      <p className={styles.viewFeatures}>
        <a
          className={styles.viewFeaturesLink}
          href="/features/"
          aria-label="View features"
        >
          <span className={styles.viewFeaturesLinkText}>View features</span>
          <span className={styles.viewFeaturesArrowIcon}>
            <RightArrow />
          </span>
        </a>
      </p>
    </div>
  </section>
);

export default FeatureCardsOContainer;
