import React from "react";

import Button from "../../Buttons/Button";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";

/* Components */
import DemoGatedForm from "./DemoGatedForm";

/* Styles */
import styles from "./DemoGatedModal.module.scss";

type ConsulationModalType = {
  headline?: string;
  subcopy?: string;
  primaryCtaText?: string;
  primaryCtaLink?: string;
  bypassCtaText?: string;
};
type ModalType = {
  gatedHeadline?: string;
  gatedSubcopy?: string;
  cliniciansSubcopy?: string;
  practiceSize?: number;
  ctaText?: string;
  cliniciansHeader?: string;
  excludeVisitorCookie?: string;
  privacyNote?: string;
  modalPrivacyNote?: string;

  buttonPrimaryText?: string;
  buttonSecondaryText?: string;
};

type DemoGatedModalPropsType = {
  trigger: boolean;
  modal: ModalType & ConsulationModalType;
  sucessHandler: (
    isShowBanner: boolean,
    fullName: string,
    email: string
  ) => void;
  isShowSucessModal: boolean;
  successActionHandler?: (isSendAction: boolean) => void;
};

const DemoGatedModal: React.FC<DemoGatedModalPropsType> = ({
  trigger,
  modal,
  sucessHandler,
  isShowSucessModal,
  successActionHandler,
}) => {
  const modalBodyStyles = styles.modalBody;
  return (
    <div
      data-testid="modal"
      className={`${styles.modalContainer} ${
        trigger ? styles.modalContainerActive : ""
      }`}
    >
      <div
        className={`${styles.modalContent}  ${
          trigger ? styles.modalContentActive : ""
        }`}
      >
        <div className={modalBodyStyles}>
          <h5 data-testid="modal-header" className={styles.modalBodyTitle}>
            {isShowSucessModal ? modal?.headline : modal?.gatedHeadline}
          </h5>
          <p
            data-testid="modal-subheader"
            className={`${styles.modalBodySubtitle} ${
              isShowSucessModal && styles.modelSubTitlePadding
            }`}
          >
            {isShowSucessModal ? modal?.subcopy : modal?.gatedSubcopy}
          </p>

          {!isShowSucessModal && (
            <DemoGatedForm
              modalCliniciansSubcopy={modal?.cliniciansSubcopy}
              modalPracticeSize={modal?.practiceSize}
              modalCliniciansHeader={modal?.cliniciansHeader}
              modalPrivacyNote={modal?.privacyNote}
              modalCtaText={modal?.ctaText}
              callback={sucessHandler}
            />
          )}
          {isShowSucessModal && (
            <>
              <div className={styles.successButtonWrapper}>
                <PrimaryButton
                  buttonText={modal?.primaryCtaText}
                  onClickHandler={() =>
                    successActionHandler(true, modal?.primaryCtaText)
                  }
                  noMxp={true}
                />
              </div>
              <div className={styles.fullWidth}>
                <Button
                  variant="hollow-red"
                  buttonText={modal?.bypassCtaText}
                  onClick={() =>
                    successActionHandler(false, modal?.bypassCtaText)
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoGatedModal;
