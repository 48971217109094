import { graphql, useStaticQuery } from "gatsby";
import { FeatureCardsOType } from "../Types/AcfGroupTypes/FeatureCardsO.type";

export const GlobalFeaturesCardsQuery = (): FeatureCardsOType => {
  const {
    allWp: {
      edges: [
        {
          node: {
            graphqlOptions: { globalFeaturesCardsO },
          },
        },
      ],
    },
  } = useStaticQuery(graphql`
    query GlobalFeaturesCardsQuery {
      allWp {
        edges {
          node {
            graphqlOptions {
              globalFeaturesCardsO {
                featuresCardsCta
                featuresCardsCtaText
                featuresCardsList {
                  featuresCardsItemBodycopy
                  featuresCardsItemTitle
                  featuresCardsItemIcon {
                    altText
                    mediaItemUrl
                    localFile {
                      publicURL
                    }
                  }
                }
                featuresCardsSubcopy
                featuresCardsTitle
              }
            }
          }
        }
      }
    }
  `);
  return globalFeaturesCardsO;
};
