import * as React from "react";
import { ArrayElementType, FeatureCardsOType } from "@types";
import styles from "./FeatureCard.module.scss";

export type FeatureCardType = ArrayElementType<
  FeatureCardsOType["featuresCardsList"]
>;

const FeatureCard: React.FC<FeatureCardType> = ({
  featuresCardsItemIcon,
  featuresCardsItemTitle,
  featuresCardsItemBodycopy,
  page,
}) => (
  <div data-testid="div:FeatureCard" className={styles.featureItem}>
    <div className={styles.featureIcon}>
      <img
        src={featuresCardsItemIcon.mediaItemUrl}
        alt={featuresCardsItemIcon.altText}
      />
    </div>
    <h3
      className={`${styles.featureTitle} ${
        page === "Home" ? styles.featureTitleHome : ""
      }`}
      dangerouslySetInnerHTML={{ __html: featuresCardsItemTitle }}
    />
    <p className={styles.featureBody}>{featuresCardsItemBodycopy}</p>
  </div>
);

export default FeatureCard;
